<template>
	<div class="CommodityMerchant">
		<w-navTab :titleText="title"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<template v-if="type != -1">
			<div class="header-top-merchant" @click="$router.push({ name: 'CommodityMerchantEdit',params:{id: detail.merchant_id}})">店铺管理</div>
			<div class="header-top-order" @click="$router.push({ name: 'Merchant',params:{id: detail.merchant_id}})">客户订单</div>
		</template>
		<div class="commodity-top">
			<van-swipe :autoplay="3000" :initial-swipe="0" indicator-color="#3377ff">
				<van-swipe-item v-for="item in detail.banner">
					<img :src="item" alt="" />
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="container">
			<div class="container-nav">
				<div class="nav-box">
					<div class="nav-box-left">
						<div class="box-left-img"><img :src="detail.logo" alt="" /></div>
						<div class="box-left-body">
							<div class="left-body-title">{{detail.name}}</div>
							<div class="left-body-text">商品种类：{{detail.product_num}}种</div>
						</div>
					</div>
					<div class="nav-box-right">
						<div class="nav-box-right-text">已售出商品：{{detail.sale_num}}件</div>
						<div class="nav-box-right-text">消耗爱心积分：{{detail.integral_num}}</div>
					</div>
					
				</div>
			</div>
			<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
				<div class="container-body">
					<div class="item-box" v-for="item in companyList"
						@click="$router.push({ name: 'CommodityDetail', params: { id: item.id } })">
						<div class="item-box-picture"><img :src="item.thumb" alt="" /></div>
						<div class="item-box-bottom">
							<div class="item-box-text">{{ item.title }}</div>
							<div class="item-box-price">
								<div class="price-left">
									<div class="price-title">
										<span>￥</span>{{item.price}}
									</div>
									<!-- <div class="price-text">
										￥{{item.price}}{{parseInt(item.price*100 - item.discount*100)/parseInt(100).toFixed(2)}}
									</div> -->
								</div>
								<div class="price-box">
									<div class="right-text">爱心积分{{item.integral}}</div>
									<div class="right-number">抵{{item.discount}}元</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</VantList>
		</div>
	</div>
</template>

<script>
	import VantList from '@/components/VantList';
	import LoadingPage from '@/components/LoadingPage';
	import merchant from '@/api/merchant';
	import VantVendor from '@/vendor/vant';
	import Config from '@/vendor/config';
	export default {
		name: 'CommodityMerchant',
		data() {
			return {
				id: '',
				page: 1,
				limit: 10,
				loading: true,
				finished: false,
				isEmpty: false,
				loadingPage: false,
				companyList: [],
				detail: '',
				title: '',
				type: -1
			}
		},
		computed: {
			userInfo() {
				return this.$store.state.user.userInfo;
			}
		},
		created() {
			this.id = this.$route.params.id;
			this.getMerchantInfo()
			this.onLoad()
		},
		methods: {
			getMerchantInfo(){
				merchant.merchantIndex({merchant_id: this.id})
					.then(result=>{
						this.detail = result.data
						this.title = result.data.name + '店铺'
						this.type = this.$store.state.user.userInfo.merchant_id.findIndex(item=> item == this.id)
						wx.onMenuShareTimeline({
							title: this.detail.name + '-' + document.title,
							link: window.Location.href,
							imgUrl: Config.shareLogoUrl,
							success: () => {
								Vant.Toast.success('分享成功');
							}
						});
						
						wx.onMenuShareAppMessage({
							title: this.detail.name + '-' + document.title,
							link: window.Location.href,
							imgUrl: Config.shareLogoUrl,
							success: () => {
								Vant.Toast.success('分享成功');
							}
						});
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			// 商品列表
			onLoad() {
				this.loading = true;
				let params = {
					page: this.page,
					limit: this.limit,
					merchant_id: this.id,
				};
				merchant.merchantProducts(params)
					.then(result => {
						this.page++;
						result.data.list.forEach((item, key) => {
							item.value = 0;
						});
						this.companyList = this.companyList.concat(result.data.list);

						this.loading = false;
						this.loadingPage = false;

						if (this.companyList.length <= 0) {
							this.isEmpty = true;
						}

						if (this.companyList.length >= result.data.total) {
							this.finished = true;
						}
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			toSearch() {
				this.$router.push({
					name: 'CommoditySearch',
				});
			},
		},
		components: {
			VantList,
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.CommodityMerchant {
		min-height: 100vh;
		background: #F2F2F2;
		box-sizing: border-box;
		position: relative;
		.header-top-merchant,
		.header-top-order {
			position: absolute;
			top: 60px;
			right: 0;
			padding: 3px 10px;
			color: #fff;
			background: rgba(0, 0, 0, 0.5);
			border-radius: 14.5px 0px 0px 14.5px;
			box-sizing: border-box;
			z-index: 999;
		}
		.header-top-order{
			top: 95px;
		}
		.commodity-top {
			width: 100%;
			height: 200px;
			margin-bottom: 10px;
			box-sizing: border-box;
			img {
				width: 100%;
				height: 200px;
				object-fit: cover;
			}
			/deep/.van-swipe {
				max-height: 200px !important;
				.van-swipe__indicators{
					bottom: 30px !important;
				}
				.van-swipe__indicator {
					width: 8px;
					height: 8px;
					background: #3377FF;
					opacity: 0.5;
				}
				
				.van-swipe__indicator--active {
					width: 40px;
					opacity: 1;
					border-radius: 20px;
				}
			}
		}

		.container {
			height: 100%;
			
			.container-nav {
				background: #f2f2f2;
				border-radius: 15px 15px 0px 0px;
				width: 100%;
				position: relative;
				z-index: 10;
				margin-top: -25px;
				padding-top: 12px;

				.nav-box{
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 0 10px;
					.nav-box-left{
						display: flex;
						align-items: center;
						.box-left-img{
							width: 40px;
							height: 40px;
							min-width: 40px;
							img{
								width: 100%;
								height: 100%;
								border-radius: 50%;
								display: block;
								object-fit: cover;
							}
						}
						.box-left-body{
							padding-left: 10px;
							box-sizing: border-box;
							.left-body-title{
								font-size: 16px;
								line-height: 19px;
								color: #222;
							}
							.left-body-text{
								font-size: 12px;
								line-height: 15px;
								color: #777;
								margin-top: 2px;
							}
						}
					}
					.nav-box-right{
						.nav-box-right-text{
							font-size: 12px;
							line-height: 20px;
							color: #777;
						}
					}
				}
			}


			.container-body {
				padding: 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				background: #f2f2f2;
				padding-bottom: 0;

				.item-box {
					width: 172px;
					background: #fff;
					box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);
					border-radius: 8px;
					overflow: hidden;
					margin-bottom: 10px;

					.item-box-picture {
						width: 172px;
						height: 170px;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.item-box-bottom{
						padding: 10px;
						box-sizing: border-box;
						.item-box-text {
							min-height: 44px;
							font-size: 14px;
							line-height: 22px;
							color: #000;
							box-sizing: border-box;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}
						
						.item-box-price {
							margin-top: 4px;
							box-sizing: border-box;
						
							.price-left {
								display: flex;
								align-items: center;
						
								.price-title {
									font-size: 16px;
									color: #f43e3d;
						
									span {
										font-size: 12px;
									}
								}
						
								.price-text {
									padding-left: 4px;
									font-size: 12px;
									line-height: 22px;
									text-decoration-line: line-through;
									color: #909399;
								}
							}
						
							.price-box {
								display: flex;
								align-items: center;
								margin-top: 4px;
								box-sizing: border-box;
						
								.right-text {
									font-size: 10px;
									line-height: 16px;
									color: #E95749;
									padding: 0 3px 0 3px;
									border: 1px solid #E95749;
									border-right: 1px dashed #E95749;
									box-sizing: border-box;
									background: rgba(233, 87, 73, .1);
									border-top-left-radius: 2px;
									border-bottom-left-radius: 2px;
								}
						
								.right-number {
									padding: 0 3px;
									font-size: 10px;
									line-height: 16px;
									border: 1px solid #E95749;
									border-left: 0;
									color: #E95749;
									box-sizing: border-box;
									border-top-right-radius: 2px;
									border-bottom-right-radius: 2px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>